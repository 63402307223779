import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
import { useSourceContext } from '../SourceContext/SourceProvider'
import { useSourcePluginContext } from '../SourcePluginContext'
import { AppointmentNote, Appointment } from '../../types/types'
import useAppointmentNotes from '../../hooks/useAppointmentNotes'
import useMember from '../../hooks/useMember'
import { v4 as uuidv4 } from 'uuid'
import { useAlertContext } from '../AlertContext'
import {
  listScreens,
  nextScreen as getNextScreen,
  previousScreen as getPreviousScreen,
} from '../../utils/post-appointment.utils'

interface PostAppointmentInterface {
  currentScreen: PostAppointmentScreen
  sourceTags: Tag[]
  selectedTags: Tag[]
  memberTags: Tag[]
  noFollowUpReason?: string
  labsResponse?: boolean
  imagingResponse?: boolean
  prescribedGLP1?: boolean
  referToSpecialist?: string
  timeline: string
  note: string
  recommendedProgram?: string
  stepper: PostAppointmentScreenStepper[]
  selectedFiles: FileList[]
  selectedNote: AppointmentNote | undefined
  selectedAppointment: Appointment | undefined
  isLoading: boolean
  showNoFollowUp: boolean
  noFollowUpOtherReason: string
  setCurrentScreen: (screen: PostAppointmentScreen) => void
  updateTestsResponse: (
    labsOrdered: boolean,
    imagingOrdered: boolean,
    prescribedGLP1: boolean,
    referToSpecialist?: string
  ) => void
  updateTimeline: (
    event: React.ChangeEvent<{
      value: string
    }>
  ) => void
  nextScreen: (appointment?: Appointment, context?: NextScreenContext) => void
  previousScreen: () => void
  updateTags: (value: Tag) => void
  updateNoReasonFollowUp: (value: string) => void
  updateNote: (
    event: React.ChangeEvent<{
      value: string
    }>
  ) => void
  updateRecommendedProgram: (
    event: React.ChangeEvent<{ value: string }>
  ) => void
  handleAttachments: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleRemoveAttachment: (index: number) => void
  updateSelectedNote: (note: AppointmentNote | undefined) => void
  updateSelectedAppointment: (appointment: Appointment | undefined) => void
  confirmAndSend: (updateNoteId?: string, saveAsDraft?: boolean) => void
  updateShowFollowUp: () => void
  updateNoFollowUpOtherReason: (
    event: React.ChangeEvent<{
      value: string
    }>
  ) => void
}
export type Tag = {
  id: string
  name: string
  description: string | null
  created_at: string
}
export const PostAppointmentContext = createContext<PostAppointmentInterface>(
  {} as PostAppointmentInterface
)

interface PostAppointmentProps {
  children: React.ReactNode
}
export type PostAppointmentScreen =
  | 'initial'
  | 'tagging'
  | 'recommended-program'
  | 'tests'
  | 'timeline'
  | 'note'
  | 'review'

export type PostAppointmentScreenStepper = {
  title: string
  index: number
  screen: PostAppointmentScreen
}

export type NextScreenContext = {
  moreTestsOrdered?: boolean
  noFollowUp?: boolean
}

export const PostAppointmentProvider: React.FC<PostAppointmentProps> = ({
  children,
}) => {
  const { setAlertText } = useAlertContext()
  const { pluginContext } = useSourcePluginContext()
  const { member } = useMember(pluginContext?.member ?? undefined)
  const { source } = useSourceContext()
  const { handleSaveNote } = useAppointmentNotes()
  const [labsResponse, setLabsResponse] = useState<boolean | undefined>(
    undefined
  )
  const [imagingResponse, setImagingResponse] = useState<boolean | undefined>(
    undefined
  )
  const [prescribedGLP1, setPrescribedGLP1] = useState<boolean | undefined>(
    undefined
  )
  const [referToSpecialist, setReferToSpecialist] = useState<
    string | undefined
  >(undefined)
  const [currentScreen, setCurrentScreen] =
    useState<PostAppointmentScreen>('initial')
  const [selectedTags, setSelectedTags] = useState<Tag[]>([])
  const [memberTags, setMemberTags] = useState<Tag[]>([])
  const [sourceTags, setSourceTags] = useState<Tag[]>([])
  const [noFollowUpReason, setNoFollowUpReason] = useState<string | undefined>(
    undefined
  )
  const [timeline, setTimeline] = useState<string>('')
  const [note, setNote] = useState<string>('')
  const [recommendedProgram, setRecommendedProgram] = useState<
    string | undefined
  >(undefined)
  const [selectedFiles, setSelectedFiles] = useState<FileList[]>([])
  const [selectedNote, setSelectedNote] = useState<AppointmentNote | undefined>(
    undefined
  )
  const [selectedAppointment, setSelectedAppointment] = useState<
    Appointment | undefined
  >(undefined)
  const [stepper, setStepper] = useState<PostAppointmentScreenStepper[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showNoFollowUp, setShowNoFollowUp] = useState<boolean>(false)
  const [noFollowUpOtherReason, setNoFollowUpOtherReason] = useState<string>('')

  useEffect(() => {
    fetchTags()
  }, [source])

  useEffect(() => {
    if (pluginContext?.member) {
      if (currentScreen === 'tagging') {
        fetchMemberTags(pluginContext.member)
      }
    } else {
      setSelectedTags([])
    }
  }, [pluginContext?.member, currentScreen])

  const fetchTags = async () => {
    const tags = await source.tags.list().catch(() => ({ data: [] }))
    setSourceTags(tags.data)
  }

  const fetchMemberTags = async (memberId: string) => {
    const tags = await source.members
      .retrieve(memberId, { expand: ['tags'] })
      .then((member) => member.tags as Tag[])
      .catch(() => [])
    setSelectedTags(Array.from(new Set([...selectedTags, ...tags])))
    setMemberTags(tags)
  }

  const updateTags = (value: Tag) => {
    const memberTagsIds = memberTags.map((item) => item.id)

    if (memberTagsIds.includes(value.id)) {
      const newTags = memberTags.filter((item) => item.id !== value.id)
      setMemberTags(newTags)
    }

    const selectedTagsIds = selectedTags.map((item) => item.id)

    if (selectedTagsIds.includes(value.id)) {
      const newTags = selectedTags.filter((item) => item.id !== value.id)
      setSelectedTags(newTags)
    } else {
      const newTags = new Set([...selectedTags, value])
      setSelectedTags(Array.from(newTags))
    }
  }

  const updateTestsResponse = (
    labsOrdered: boolean,
    imagingOrdered: boolean,
    prescribedGLP1: boolean,
    referToSpecialist?: string
  ) => {
    const testsOrdered = labsOrdered || imagingOrdered
    setLabsResponse(labsOrdered)
    setImagingResponse(imagingOrdered)
    setPrescribedGLP1(prescribedGLP1)

    if (referToSpecialist) {
      setReferToSpecialist(referToSpecialist)
    }

    if (selectedAppointment?.type.category === 'Initial MD' && testsOrdered) {
      setTimeline('immediately')
    }

    nextScreen(undefined, { moreTestsOrdered: testsOrdered })
  }

  const updateNoReasonFollowUp = (value: string) => {
    setNoFollowUpReason(value)
  }

  const updateTimeline = (event: React.ChangeEvent<{ value: string }>) => {
    setTimeline(event.target.value)
  }

  const updateNote = (event: React.ChangeEvent<{ value: string }>) => {
    setNote(event.target.value)
  }

  const updateRecommendedProgram = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setRecommendedProgram(event.target.value)
  }

  const handleAttachments = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || undefined

    if (files) {
      setSelectedFiles([...selectedFiles, files])
    }
  }

  const handleRemoveAttachment = (index: number) => {
    selectedFiles.splice(index, 1)
    setSelectedFiles([...selectedFiles])
  }

  const updateSelectedNote = (note: AppointmentNote | undefined) => {
    if (note) {
      setNote(note.text)

      if (typeof note.labsOrdered === 'boolean') {
        setLabsResponse(note.labsOrdered)
      }

      if (typeof note.imagingOrdered === 'boolean') {
        setImagingResponse(note.imagingOrdered)
      }

      if (note.recommendedProgram) {
        setRecommendedProgram(note.recommendedProgram)
      }

      if (note.followup) {
        setTimeline(note.followup)
      }

      if (note.noFollowupReason) {
        setNoFollowUpReason(note.noFollowupReason)
        setNoFollowUpOtherReason(note.noFollowupReason)
      }

      if (note.sourceTagIds) {
        const savedTagIdsSet = new Set(note.sourceTagIds)
        const savedTags = sourceTags.filter((tag) => savedTagIdsSet.has(tag.id))
        setSelectedTags(savedTags)
      }
    } else {
      reset()
    }

    setSelectedNote(note)
  }

  const updateSelectedAppointment = (appointment: Appointment | undefined) => {
    setSelectedAppointment(appointment)

    if (appointment) {
      setStepper(listScreens(appointment.type))
    }
  }

  const updateShowFollowUp = useCallback(() => {
    setShowNoFollowUp(!showNoFollowUp)
    setNoFollowUpReason('')
  }, [showNoFollowUp])

  const updateNoFollowUpOtherReason = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setNoFollowUpOtherReason(event.target.value)
  }

  const confirmAndSend = async (updateNoteId?: string, saveAsDraft = false) => {
    if (!member?.sourceHealthId) {
      setAlertText('Unable to save note, no member loaded in context')
      return
    } else if (!selectedAppointment) {
      setAlertText('Unable to save, no selected appointment')
      return
    }

    setIsLoading(true)

    const memberTagsToSave = selectedTags.map((tag) => tag.id)

    if (!saveAsDraft) {
      source.members
        .update(member.sourceHealthId, {
          tags: memberTagsToSave,
        })
        .catch(() =>
          // eslint-disable-next-line
          console.error(
            `Unable to set source tags ${selectedTags
              .map((t) => t.id)
              .join(',')} to member ${member.id}`
          )
        )
    }

    const finalTimeline = showNoFollowUp ? undefined : timeline
    const finalNoFollowUpReason =
      noFollowUpReason === 'Other (please let concierge know)'
        ? noFollowUpOtherReason
        : noFollowUpReason

    return handleSaveNote({
      note: {
        id: updateNoteId ?? uuidv4(),
        text: note,
        status: saveAsDraft ? 'draft' : 'signed',
        timestamp: new Date().toISOString(),
        user: member,
        labsOrdered: labsResponse,
        imagingOrdered: imagingResponse,
        prescribedGLP1: prescribedGLP1,
        referToSpecialist: referToSpecialist,
        recommendedProgram: recommendedProgram,
        appointment: selectedAppointment,
        followup: finalTimeline,
        noFollowupReason: finalNoFollowUpReason,
        sourceTagIds: memberTagsToSave,
      },
      files: selectedFiles,
    }).then(() => {
      nextScreen()
      setIsLoading(false)
    })
  }

  const nextScreen = useCallback(
    (appointment?: Appointment, context?: NextScreenContext) => {
      let appointmentType = undefined

      if (selectedAppointment) {
        appointmentType = selectedAppointment.type
      } else if (appointment) {
        appointmentType = appointment.type
      } else {
        return
      }

      const screen = getNextScreen(currentScreen, appointmentType, context)
      setCurrentScreen(screen)
    },
    [currentScreen, selectedAppointment, showNoFollowUp, setCurrentScreen]
  )

  const reset = () => {
    setLabsResponse(undefined)
    setImagingResponse(undefined)
    setSelectedTags([])
    setSelectedFiles([])
    setStepper([])
    setNoFollowUpOtherReason('')
    setShowNoFollowUp(false)
    setNote('')
    setTimeline('')
    setRecommendedProgram(undefined)
    setNoFollowUpReason(undefined)
    setSelectedAppointment(undefined)
    setSelectedNote(undefined)
  }

  const previousScreen = useCallback(() => {
    if (!selectedAppointment) {
      return
    }

    const screen = getPreviousScreen(currentScreen, selectedAppointment.type, {
      moreTestsOrdered: labsResponse || imagingResponse,
      noFollowUp: showNoFollowUp,
    })

    if (screen === 'initial') {
      reset()
    }

    setCurrentScreen(screen)
  }, [
    currentScreen,
    selectedAppointment,
    labsResponse,
    imagingResponse,
    showNoFollowUp,
    setCurrentScreen,
  ])

  return (
    <PostAppointmentContext.Provider
      value={{
        currentScreen,
        selectedTags,
        sourceTags,
        noFollowUpReason,
        labsResponse,
        imagingResponse,
        prescribedGLP1,
        referToSpecialist,
        timeline,
        note,
        recommendedProgram,
        stepper,
        selectedFiles,
        selectedNote,
        selectedAppointment,
        isLoading,
        showNoFollowUp,
        noFollowUpOtherReason,
        memberTags,
        setCurrentScreen,
        nextScreen,
        previousScreen,
        updateTags,
        updateTestsResponse,
        updateNoReasonFollowUp,
        updateTimeline,
        updateNote,
        updateRecommendedProgram,
        handleAttachments,
        handleRemoveAttachment,
        updateSelectedNote,
        updateSelectedAppointment,
        confirmAndSend,
        updateShowFollowUp,
        updateNoFollowUpOtherReason,
      }}
    >
      {children}
    </PostAppointmentContext.Provider>
  )
}

export const usePostAppointmentContext = (): PostAppointmentInterface =>
  useContext(PostAppointmentContext)
